<template>
  <v-card min-height="475">
    <v-card-title>
      <v-col cols="5">
        {{ title + ' ' + selectedYear }}
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="3">
        <v-select
          :items="years"
          :value="selectedYear"
          item-text="year"
          item-value="year"
          outlined
          dense
          hide-details
          @change="handleYearChange"
        ></v-select>
      </v-col>
    </v-card-title>
    <monthly-revenue-shimmer v-show="isRevenueLoading" />
    <div id="chart">
      <apexchart
        v-show="!isRevenueLoading & revenueBarGraphData.xaxis.categories.length > 0"
        ref="chart"
        type="bar"
        height="400"
        :options="revenueBarGraphData"
        :series="revenueBarGraphData.series"
      ></apexchart>
    </div>
    <v-card-text v-show="!isRevenueLoading && revenueBarGraphData.xaxis.categories.length === 0">
      <p class="ml-4 font-weight-light">
        There is no enough data. Statistics will be displayed when enough data is gathered
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import MonthlyRevenueShimmer from '@/components/partials/shimmers/MonthlyRevenueShimmer.vue'

export default {
  components: {
    apexchart: VueApexCharts,
    MonthlyRevenueShimmer,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isRevenueLoading: {
      type: Boolean,
      default: true,
    },
    revenueBarGraphData: {
      type: Object,
      default: () => ({
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [],
        },
        series: [{
          name: 'Revenue (Ksh)',
          data: [],
        }],
      }),
    },
    years: {
      type: Array,
      default: () => [new Date().getFullYear()],
    },
    selectedYear: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  methods: {
    handleYearChange(year) {
      this.$emit('year-change', year)
    },
  },

}
</script>
