<template>
  <v-row>
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.path"
        :href="'#' + tab.path"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="custom-tab-items"
    >
      <v-tab-item value="all">
        <all-statistics :years="years"></all-statistics>
      </v-tab-item>
      <v-tab-item
        v-for="station in meterStations"
        :key="station.id"
        :value="station.id"
      >
        <per-station-statistics
          :years="years"
          :meter-station-id="station.id"
          :station-name="station.name"
        ></per-station-statistics>
      </v-tab-item>
    </v-tabs-items>
  </v-row>
</template>

<script>
import axios from 'axios'
import AllStatistics from './AllStatistics.vue'
import PerStationStatistics from './PerStationStatistics.vue'

export default {
  name: 'TransactionStatistics',
  components: {
    AllStatistics,
    PerStationStatistics,
  },
  data() {
    return {
      tab: null,
      tabs: [
        {
          title: 'All',
          path: 'all',
        },
      ],
      meterStations: [],
      years: [],
    }
  },
  mounted() {
    this.getMeterStations()
    this.getRevenueYears()
  },
  methods: {
    getRevenueYears() {
      axios
        .get('statistics/revenue-years')
        .then(response => {
          this.years = response.data.revenue_years
        })
        .catch(error => {
          console.log(error)
        })
    },
    getMeterStations() {
      axios.get('meter-stations').then(response => {
        const meterStations = response.data
        this.meterStations = meterStations
        meterStations.forEach(meterStation => {
          this.tabs.push({
            title: meterStation.name,
            path: meterStation.id,
          })
        })
      })
    },
  },
}
</script>

<style scoped>
.custom-tab-items {
    background-color: transparent !important;
}
</style>
