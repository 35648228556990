<template>
  <v-col>
    <v-row class="mt-1 mb-1">
      <v-col
        cols="2"
        class="ml-10"
      >
        <vue-skeleton-loader
          rounded
          :width="100"
          :height="360"
        />
      </v-col>
      <v-col cols="2">
        <vue-skeleton-loader
          style="margin-top: 120px"
          rounded
          :width="100"
          :height="240"
        />
      </v-col>
      <v-col cols="2">
        <vue-skeleton-loader
          style="margin-top: 50px"
          rounded
          :width="100"
          :height="310"
        />
      </v-col>
      <v-col cols="2">
        <vue-skeleton-loader
          style="margin-top: 290px"
          rounded
          :width="100"
          :height="70"
        />
      </v-col>
      <v-col cols="2">
        <vue-skeleton-loader
          style="margin-top: 50px"
          rounded
          :width="100"
          :height="310"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    VueSkeletonLoader,
  },

}
</script>

<style>

</style>
