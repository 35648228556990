export default {
  methods: {
    calculateBarWidth(numLabels) {
      const maxLabels = numLabels
      const minLabels = 5
      const maxBarWidth = 30
      const minBarWidth = 20

      if (numLabels <= minLabels) {
        return minBarWidth
      }
      if (numLabels >= maxLabels) {
        return maxBarWidth
      }

      return (
        minBarWidth
        + ((maxBarWidth - minBarWidth) * (numLabels - minLabels)) / (maxLabels - minLabels)
        + ((numLabels - minLabels) * 3).toFixed(2)
      ).toFixed(2)
    },
  },
}
