<template>
  <v-row
    class="match-height mt-4"
  >
    <v-col>
      <revenue-card
        :current-revenue-name="'Today'"
        :current-revenue="todayRevenue.today_revenue"
        :previous-revenue-name="'Yesterday'"
        :previous-revenue="todayRevenue.yesterday_revenue"
        :is-revenue-loading="isTodayRevenueLoading"
      />
    </v-col>
    <v-col>
      <revenue-card
        :current-revenue-name="'This Week'"
        :current-revenue="thisWeekRevenue.this_week_revenue"
        :previous-revenue-name="'Last Week'"
        :previous-revenue="thisWeekRevenue.last_week_revenue"
        :is-revenue-loading="isThisWeekRevenueLoading"
      />
    </v-col>
    <v-col>
      <revenue-card
        :current-revenue-name="'This Month'"
        :current-revenue="thisMonthRevenue.this_month_revenue"
        :previous-revenue-name="'Last Month'"
        :previous-revenue="thisMonthRevenue.last_month_revenue"
        :is-revenue-loading="isThisMonthRevenueLoading"
      />
    </v-col>
    <v-col>
      <revenue-card
        :current-revenue-name="'This Year'"
        :current-revenue="thisYearRevenue.this_year_revenue"
        :previous-revenue-name="'Last Year'"
        :previous-revenue="thisYearRevenue.last_year_revenue"
        :is-revenue-loading="isThisYearRevenueLoading"
      />
    </v-col>
    <v-col cols="12">
      <revenue-bar-graph
        :is-revenue-loading="isRevenueBarGraphLoading"
        :revenue-bar-graph-data="revenueBarGraphData"
        :years="years"
        :selected-year="selectedAllRevenueYear"
        :title="stationName + ' Revenue'"
        @year-change="onAllRevenueYearChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import RevenueCard from '@/views/statistics/transactions/components/RevenueCard.vue'
import RevenueBarGraph from './components/RevenueBarGraph.vue'
import calculateBarWidth from '@/mixins/calculateBarWidth'

export default {
  name: 'PerStationStatistics',
  components: { RevenueCard, RevenueBarGraph },
  mixins: [calculateBarWidth],
  props: {
    stationName: {
      type: String,
      required: true,
    },
    years: {
      type: Array,
      required: true,
    },
    meterStationId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isTodayRevenueLoading: true,
    isThisWeekRevenueLoading: true,
    isThisMonthRevenueLoading: true,
    isThisYearRevenueLoading: true,
    isRevenueBarGraphLoading: true,
    todayRevenue: {
      today_revenue: 0,
      yesterday_revenue: 0,
    },
    thisWeekRevenue: {
      this_week_revenue: 0,
      last_week_revenue: 0,
    },
    thisMonthRevenue: {
      this_month_revenue: 0,
      last_month_revenue: 0,
    },
    thisYearRevenue: {
      this_year_revenue: 0,
      last_year_revenue: 0,
    },
    revenueBarGraphData: {
      chart: {
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: [],
      },
      series: [{
        name: 'Revenue (Ksh)',
        data: [],
      }],
    },
    selectedAllRevenueYear: new Date().getFullYear(),
    selectedPerStationRevenueYear: new Date().getFullYear(),
  }),
  mounted() {
    this.getTodayRevenue()
    this.getThisWeekRevenue()
    this.getThisMonthRevenue()
    this.getThisYearRevenue()
    this.getRevenueOfBarChart()
  },
  methods: {
    getTodayRevenue() {
      this.isTodayRevenueLoading = true
      axios
        .get(`statistics/today-revenue?station_id=${this.meterStationId}`)
        .then(response => {
          this.todayRevenue = response.data
          this.isTodayRevenueLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isTodayRevenueLoading = false
        })
    },
    getThisWeekRevenue() {
      this.isThisWeekRevenueLoading = true
      axios
        .get(`statistics/this-week-revenue?station_id=${this.meterStationId}`)
        .then(response => {
          this.thisWeekRevenue = response.data
          this.isThisWeekRevenueLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isThisWeekRevenueLoading = false
        })
    },
    getThisMonthRevenue() {
      this.isThisMonthRevenueLoading = true
      axios
        .get(`statistics/this-month-revenue?station_id=${this.meterStationId}`)
        .then(response => {
          this.thisMonthRevenue = response.data
          this.isThisMonthRevenueLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isThisMonthRevenueLoading = false
        })
    },
    getThisYearRevenue() {
      this.isThisYearRevenueLoading = true
      axios
        .get(`statistics/this-year-revenue?station_id=${this.meterStationId}`)
        .then(response => {
          this.thisYearRevenue = response.data
          this.isThisYearRevenueLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isThisYearRevenueLoading = false
        })
    },
    getRevenueOfBarChart() {
      this.isRevenueBarGraphLoading = true
      axios
        .get(`statistics/monthly-revenue?station_id=${this.meterStationId}&year=${this.selectedAllRevenueYear}`)
        .then(response => {
          const { data } = response
          const chartLabels = []
          const chartValues = []

          data.forEach(element => {
            chartValues.push(element.value)
            chartLabels.push(element.name)
          })
          const ref = this

          this.revenueBarGraphData = {
            ...this.revenueBarGraphData,
            ...{
              xaxis: {
                categories: chartLabels,
              },
              series: [{
                name: 'Revenue',
                data: chartValues,
              }],
              yaxis: {
                title: {
                  text: 'Amount (Ksh)',
                },
              },
              tooltip: {
                y: {
                  formatter(val) {
                    return `Ksh ${ref.formatCurrency(val)}`
                  },
                },
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: `${this.calculateBarWidth((chartLabels.length))}%`,
                  endingShape: 'rounded',
                },
              },
            },
          }
          this.isRevenueBarGraphLoading = false
        })
        .catch(error => {
          this.isRevenueBarGraphLoading = false
          console.log(error)
          this.$notification.error(error.response.data.message)
        })
    },
    onAllRevenueYearChange(year) {
      this.selectedAllRevenueYear = year
      this.getRevenueOfBarChart()
    },
    formatCurrency(value) {
      return this.$options.filters.formatCurrency(value)
    },
  },
}
</script>
