<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Revenue {{ currentRevenueName }}</span>
    </v-card-title>

    <v-card-text
      v-show="isRevenueLoading"
    >
      <revenue-card-shimmer-vue />
    </v-card-text>

    <v-card-text
      v-show="!isRevenueLoading"
    >
      <div class="d-flex align-center">
        <h1 class="text-3xl font-weight-semibold">
          Ksh {{ currentRevenue | formatCurrency }}
        </h1>

        <div class="d-flex align-center mb-n3">
          <v-icon
            size="40"
            :style="[currentRevenue > previousRevenue ? {'color' : '#56CA00'} : {'color' : '#E53935'}]"
          >
            {{ differenceIcon }}
          </v-icon>
          <span
            v-show="!isNaN(percentageDifference) && percentageDifference !== 0"
            class="text-base font-weight-medium ms-n2"
            :style="[currentRevenue > previousRevenue ? {'color' : '#56CA00'} : {'color' : '#E53935'}]"
            :class="{ 'success--text': currentRevenue > previousRevenue, 'red darken-1--text': currentRevenue < previousRevenue}"
          >{{ percentageDifference | positive }}%</span>
        </div>
      </div>

      <h4 class="mt-2 font-weight-medium">
        Compared to Ksh {{ previousRevenue | formatCurrency }} {{ previousRevenueName }}
      </h4>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMenuUp, mdiMenuDown } from '@mdi/js'
import RevenueCardShimmerVue from './RevenueCardShimmer.vue'

export default {
  filters: {
    positive(value) {
      return Math.abs(value)
    },
  },
  components: {
    RevenueCardShimmerVue,
  },
  props: {
    currentRevenueName: {
      type: String,
      required: true,
    },
    currentRevenue: {
      type: Number,
      required: true,
    },
    previousRevenueName: {
      type: String,
      required: true,
    },
    previousRevenue: {
      type: Number,
      required: true,
    },
    isRevenueLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      icons: { mdiMenuUp, mdiMenuDown },
    }
  },
  computed: {
    differenceIcon() {
      if (this.currentRevenue > this.previousRevenue) {
        return this.icons.mdiMenuUp
      }
      if (this.currentRevenue < this.previousRevenue) {
        return this.icons.mdiMenuDown
      }

      return null
    },
    percentageDifference() {
      const difference = this.currentRevenue - this.previousRevenue
      const average = (this.currentRevenue + this.previousRevenue) / 2

      return Math.round((difference / average) * 100)
    },
  },
}
</script>
